<template>
  <div class="page account-create-page container" v-if="currentAccount">
    <h1>Create account</h1>
    <hr />

    <form @submit.prevent="create">
      <div class="mb-3">
        <label for="login" class="form-label">Login</label>
        <input type="text" class="form-control" id="login" v-model="login" autocomplete="username" />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <div class="password">
          <input type="text" class="form-control" id="password" v-model="password" autocomplete="new-password" />
          <i class="fa fa-dice" @click="generatePassword"></i>
        </div>
      </div>
      <div class="mb-3">
        <label for="company" class="form-label">Company</label>
        <SelectWithSearch inputId="company" :items="companies" @search="onCompanySearch" @clear="onCompanyClear" />
      </div>
      <div class="mb-3">
        <label class="form-label">Permissions</label>
        <div v-if="permissions.length > 0">
          <span class="permission badge bg-primary" v-for="permission in permissions"
            >{{ permission }} <i class="fa fa-times" @click="removePermission(permission)"></i
          ></span>
        </div>
        <div v-else class="text-muted">None</div>
      </div>

      <div class="add-permission mb-3">
        <label for="add-permission" class="form-label">Add permission</label>
        <SelectWithSearch
          inputId="add-permission"
          :inputValue="selectedPermission"
          :items="availablePermissions"
          @search="onPermissionSearch"
        />
        <button class="btn btn-primary col" type="button" @click="addPermission">Add</button>
      </div>

      <button class="btn btn-primary">Create account</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCompanyList, getPermissionList } from '@/api';
import { accountService } from '@/modules/account/account.service';
import { randomString, RandomStringFlags } from 'fifty-seven-llc-common/dist/random';
import SelectWithSearch from '@/components/SelectWithSearch';

export default {
  name: 'AccountCreatePage',
  components: { SelectWithSearch },
  computed: {
    ...mapGetters(['account/current', 'permission/list']),
    currentAccount() {
      return this['account/current'];
    },
    permissionList() {
      return this['permission/list'];
    },
  },
  data: () => ({
    login: '',
    password: '',
    permissions: [],

    companies: [],
    company: null,

    availablePermissions: [],
    selectedPermission: null,
  }),
  methods: {
    generatePassword() {
      this.password = randomString(16, RandomStringFlags.disableExtraCharacters);
    },

    async onCompanySearch(search) {
      const response = await getCompanyList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.companies = response.data.companies.map(i => ({
        title: i.name,
        onClick: () => this.setCompany(i),
      }));
    },

    onCompanyClear() {
      this.setCompany(null);
    },

    setCompany(value) {
      this.company = value;
    },

    async onPermissionSearch(search) {
      const response = await getPermissionList(search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.availablePermissions = response.data.permissions
        .filter(i => !this.permissions.includes(i.key))
        .map(i => ({
          title: i.key,
          subtitle: i.title,
          onClick: () => this.selectPermission(i),
        }));
    },

    selectPermission(permission) {
      this.selectedPermission = permission.key;
    },

    addPermission() {
      if (!this.permissions.includes(this.selectedPermission)) {
        this.permissions.push(this.selectedPermission);
      }

      this.selectedPermission = null;
    },

    removePermission(permission) {
      this.permissions.splice(this.permissions.indexOf(permission), 1);
    },

    async create() {
      if (!this.login) {
        return this.$toast.error("Login can't be empty.");
      }

      if (!this.password) {
        return this.$toast.error("Password can't be empty.");
      }

      if (!this.company) {
        return this.$toast.error('You must select a company.');
      }

      try {
        await accountService.create(this.login, this.password, this.company.id, this.permissions);
        this.$toast.success('Account successful created.', { duration: 2000 });
        setTimeout(() => this.$router.push({ name: 'accounts' }), 2000);
      } catch (err) {
        this.$toast.error(err.toString());
      }
    },
  },
};
</script>

<style scoped>
.account-create-page {
  padding: 2em 0;
}

.password {
  position: relative;
  width: 100%;
}

.password i {
  color: #212529;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.permission {
  margin: 0.2em;
}

.permission i {
  margin-left: 0.2em;
  cursor: pointer;
}

.add-permission label {
  display: block;
}

.add-permission button {
  height: 2.5em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>

<style>
/* .add-permission input {
  width: auto;
  margin: 0 0.2em 0 0;
  display: inline-block;
} */

.add-permission .select-with-search {
  width: 14em;
  float: left;
}

.add-permission .select-with-search .input-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
